import '@fortawesome/fontawesome-svg-core/styles.css';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect } from 'react';
import { useAnalytics } from '../hooks/useAnalytics';
import FeatureFlagProvider from '../providers/FeatureFlagProvider';
import Global from '../styles/Global';
import { SITE_URL } from '../utils/constants';
import { getHostnameFromURL, loadFromApi } from '../utils/http';
import { WhiteLabelProvider, fetchWhiteLabelConfigByHostname } from '../providers/WhiteLabelProvider';
import CombinedThemeProvider from '../providers/CombinedThemeProvider';
import { WhiteLabelConfig } from '../types/api/whiteLabel';
import { appWithTranslation } from 'next-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import '../styles/global.css';
import { ApiProvider } from 'providers/ApiProvider';
import useDataLayer from '../hooks/useDataLayer';
import { useAtomValue } from 'jotai';
import { userState } from '../state/userState';

function App({ Component, pageProps, router, whiteLabelConfig }: ExtendedAppProps) {
  useAnalytics(router.asPath); // This also tracks the page load
  const { setAttributionCookies, trackPageNavigation, setWhiteLabelCookies } = useDataLayer();
  const { status } = useAtomValue(userState);

  useEffect(() => {
    if (status === 'loaded') {
      loadFromApi('user/referrer', { referrer: document.referrer, url: window.location.href });
      trackPageNavigation();
      setAttributionCookies();
      setWhiteLabelCookies(whiteLabelConfig);

      // Track all page views
      router.events.on('routeChangeComplete', trackPageNavigation);

      return () => {
        router.events.off('routeChangeComplete', trackPageNavigation);
      };
    }
  }, [status]);

  return (
    <I18nextProvider i18n={i18n}>
      <FeatureFlagProvider>
        <WhiteLabelProvider whiteLabelConfig={whiteLabelConfig}>
          <CombinedThemeProvider>
            <Global />
            <Head>
              <meta name="viewport" content="width=device-width,initial-scale=1" />
              <link rel="icon" href={whiteLabelConfig.theme.faviconUrl} />
            </Head>
            <ApiProvider>
              <Component {...pageProps} />
            </ApiProvider>
          </CombinedThemeProvider>
        </WhiteLabelProvider>
      </FeatureFlagProvider>
    </I18nextProvider>
  );
}

interface ExtendedAppProps extends AppProps {
  whiteLabelConfig: WhiteLabelConfig;
}

App.getInitialProps = async ({ ctx }: AppContext): Promise<{ whiteLabelConfig: WhiteLabelConfig }> => {
  const hostname = getHostnameFromURL(ctx?.req?.headers.host || SITE_URL);
  const whiteLabelConfig = await fetchWhiteLabelConfigByHostname(hostname);

  // If white label, redirect to default page, when landing on home page.
  if (whiteLabelConfig.isWhiteLabel && ctx?.pathname === '/') {
    const { auth } = ctx?.query || {};
    if (!auth) {
      if (ctx?.res) {
        // On the server, we'll use an HTTP response to
        // redirect with the status code of our choice.
        // 307 is for temporary redirects.
        ctx.res.writeHead(307, { Location: `/${whiteLabelConfig.defaultPage}` });
        ctx.res.end();
      } else {
        // We'll redirect to the external page using
        // `window.location`.
        window.location.assign(`/${whiteLabelConfig.defaultPage}`);
        // While the page is loading, code execution will
        // continue, so we'll await a never-resolving
        // promise to make sure our page never
        // gets rendered.
        await new Promise((resolve) => {});
      }
    }
  }

  return { whiteLabelConfig };
};

export default appWithTranslation(App);
