import { atom } from 'jotai';
import { LoginModalStatus } from '../types/common';
import { UserAPIResult } from './../types/api/userTypes';

const UserStateStatus = {
  Uninitialized: 'uninitialized',
  Loading: 'loading',
  Loaded: 'loaded',
  Error: 'error',
} as const;
export type UserStateStatus = (typeof UserStateStatus)[keyof typeof UserStateStatus];

interface UserState {
  user?: UserAPIResult;
  userLoginEmail?: string;
  loginModalStatus?: LoginModalStatus;
  status: UserStateStatus;
  showLoginPopup?: boolean;
  onLogin?: (user?: UserAPIResult) => Promise<void>;
}

export const userState = atom<UserState>({
  status: UserStateStatus.Uninitialized,
});
