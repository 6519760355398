import { IConfig, InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client';
import { UNLEASH_PROXY_APP_NAME, UNLEASH_PROXY_REFRESH_INTERVAL, UNLEASH_PROXY_TOKEN, UNLEASH_PROXY_URL } from './constants';

const unleashConfig: IConfig = {
  url: UNLEASH_PROXY_URL,
  clientKey: UNLEASH_PROXY_TOKEN,
  appName: UNLEASH_PROXY_APP_NAME,
  refreshInterval: Number(UNLEASH_PROXY_REFRESH_INTERVAL),
  environment: UNLEASH_PROXY_APP_NAME,
};

const ssrOptions = typeof window !== 'undefined' ? {} : { fetch, storageProvider: new InMemoryStorageProvider() };

export let unleashClientReady = false;

export let unleashClient: UnleashClient;

try {
  unleashClient = new UnleashClient({ ...unleashConfig, ...ssrOptions });

  unleashClient.on('ready', () => {
    unleashClientReady = true;
  });

  unleashClient.on('error', (error: Error) => {
    console.error('Unleash client error', error);
    unleashClient.stop();
  });
} catch (error) {
  console.error('Unleash client failed to start', error);
}
