import React, { createContext, useContext, ReactNode } from 'react';
import { OpenAPI, PropertiesService } from '../tenant-client';
import { TENANT_API_BASE_URL } from 'utils/constants';

export interface IApiContext {
  api: typeof PropertiesService;
}

const ApiContext = createContext<IApiContext | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

OpenAPI.WITH_CREDENTIALS = true;
OpenAPI.BASE = TENANT_API_BASE_URL;

export const ApiProvider: React.FC<AuthProviderProps> = ({ children }) => {
  return <ApiContext.Provider value={{ api: PropertiesService }}>{children}</ApiContext.Provider>;
};

export const useApi = (): IApiContext => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
