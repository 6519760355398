import { createGlobalStyle } from 'styled-components';
import { PropstepTheme } from './theme';

// `react-datepicker` is a dependency of react-ui
// TODO: it would be nice to have an import via react-ui.
// May need to refactor the datepicker to make that happen.
import 'react-datepicker/dist/react-datepicker.css';
import { WhiteLabelTheme } from 'types/api/whiteLabel';

const Global = createGlobalStyle<{ theme: PropstepTheme & WhiteLabelTheme }>`
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

::selection {
  background: ${(props) => props.theme.color.textSelectionBackgroundColor};
  color: ${(props) => props.theme.color.textSelectionColor}
}

html, body, div, span, ol, ul, li, img,
h1, h2, h3, h4, h5, h6, p, small, strong, a,
form, fieldset, label, input, textarea, select, button {
  font: inherit;
  font-size: 100%;
  appearance: none;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.theme.color.secondaryTextColor};
}

ul {
  list-style: none;
}

button {
  color: inherit;
}

a, a:visited {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
`;

export default Global;
