import { useContext, ReactNode } from 'react';
import { WhiteLabelConfig } from '../types/api/whiteLabel';
import React from 'react';
import { getFromApi } from 'utils/http';

type WhiteLabelProviderProps = {
  children: ReactNode;
  whiteLabelConfig: WhiteLabelConfig;
};

const WhiteLabelContext = React.createContext<WhiteLabelConfig | null>(null);

export function WhiteLabelProvider({ children, whiteLabelConfig }: WhiteLabelProviderProps) {
  return <WhiteLabelContext.Provider value={whiteLabelConfig}>{children}</WhiteLabelContext.Provider>;
}

export function useWhiteLabel() {
  const whiteLabelContext = useContext(WhiteLabelContext);
  if (!whiteLabelContext) {
    throw new Error("'useWhiteLabel' hook must be used within a WhiteLabelProvider");
  }

  return whiteLabelContext;
}

export async function fetchWhiteLabelConfigByHostname(hostname: string = 'propstep.com'): Promise<WhiteLabelConfig> {
  return await getFromApi(`white-label-config/${hostname}`);
}
