/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PropertiesFilter } from '../models/PropertiesFilter';
import type { SearchPropertiesResponse } from '../models/SearchPropertiesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PropertiesService {

    /**
     * Search Properties
     * Search your properties with a range of filters and options
     * @param requestBody
     * @param page
     * @param pageSize
     * @returns SearchPropertiesResponse Successful Response
     * @throws ApiError
     */
    public static searchPropertiesPropertiesSearchPost(
        requestBody: PropertiesFilter,
        page: number = 1,
        pageSize: number = 20,
    ): CancelablePromise<SearchPropertiesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/properties/search',
            query: {
                'page': page,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
