import { apiObject, apiOptions } from 'rudder-sdk-js';
import { PropertyGroupAPIResult } from '../types/api/propertyGroupTypes';
import { PropertyAPIResult } from '../types/api/propertyTypes';
import { UserAPIResult } from '../types/api/userTypes';
import { getYearsDiff } from './common';
import { RUDDERSTACK_KEY, RUDDERSTACK_URL } from './constants';

let analytics: typeof import('rudder-sdk-js');

async function getAnalytics() {
  if (!analytics) {
    analytics = await import('rudder-sdk-js');
    analytics.load(RUDDERSTACK_KEY, RUDDERSTACK_URL);
    analytics.ready(() => {});
  }
  return analytics;
}

export async function page(name: string, properties: apiObject = {}) {
  const analytics = await getAnalytics();
  analytics.page(undefined, name, properties);
}

export async function track(event: string, properties: apiObject = {}, context: apiOptions = {}): Promise<void> {
  const analytics = await getAnalytics();
  return new Promise((resolve) => {
    analytics.track(event, properties, () => {
      resolve();
    });
  });
}

function getAgeAsString(dateString?: string): string {
  if (!dateString) {
    return '';
  }
  return getYearsDiff(new Date(dateString)).toString();
}

export async function identify(user: UserAPIResult) {
  const analytics = await getAnalytics();
  analytics.identify(user.id, {
    firstName: user.firstName,
    lastName: user.lastName,
    age: getAgeAsString(user.userDetails.dateOfBirth),
    birthday: user.userDetails?.dateOfBirth,
    email: user.email,
    phone: user.phone,
    address: {
      country: user.userDetails.residence?.country,
      city: user.userDetails.residence?.city,
    },
    createdAt: user.addedOn,
    description: user.userDetails?.description,
    gender: user.userDetails?.gender,
    avatar: user.image?.name,
    represent: user.userDetails?.represent,
    occupation: user.userDetails?.occupation,
  });
}

export async function reset() {
  const analytics = await getAnalytics();
  analytics.reset();
}

export function getPropertiesFromPropertyGroup(propertyGroup: PropertyGroupAPIResult) {
  return {
    projectId: propertyGroup.id,
    projectName: propertyGroup.name,
    projectHandledBy: propertyGroup.handledBy,
    label: propertyGroup.name,
    applicationType: propertyGroup.emailOnlyApplications ? 'email only' : 'chat',
    companyId: propertyGroup.ownerId,
    propertyGroupId: propertyGroup.id,
  };
}

export function getPropertiesFromProperty(property: PropertyAPIResult) {
  return { propertyId: property.id, propertyName: property.name };
}
