import { useEffect } from 'react';
import { apiObject } from 'rudder-sdk-js';
import { page, track } from '../utils/analytics';

type AnalyticsEvent = 'page' | 'track' | 'identify' | 'reset';

export function useAnalytics(name: string, properties?: apiObject, event: AnalyticsEvent = 'page', skip = false) {
  useEffect(() => {
    if (skip) {
      return;
    }
    (async () => {
      switch (event) {
        case 'page':
          await page(name, properties || {});
          return;
        case 'track':
          await track(name, properties || {});
      }
    })();
  }, [name, properties, event, skip]);
}
