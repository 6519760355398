import FlagProvider from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { unleashClient } from '../utils/feature-flags';

interface FeatureFlagProviderProps {
  children: React.ReactNode;
}

const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  useEffect(() => {
    (async () => {
      unleashClient && (await unleashClient.start());
    })();
  }, []);

  return (
    <FlagProvider unleashClient={unleashClient} startClient={false}>
      {children}
    </FlagProvider>
  );
};

export default FeatureFlagProvider;
