export const numericBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

type Size = keyof typeof numericBreakpoints;

export function isBelowBreakpoint(width: number, breakpoint: Size): boolean {
  return width < numericBreakpoints[breakpoint];
}

export const theme = {
  color: {
    green10: '#DCFFFA', // WL: availablePillBackgroundColor
    green20: '#86FFEC', // WL: Not used yet
    green30: '#4AE3D0', // WL: Not used yet
    green40: '#1EC8B4', // WL: Not used yet
    green50: '#00B4A0', // WL: primaryBackgroundColor & availablePillTextColor (replace with primaryBackgroundColor)
    green60: '#008A7A', // WL: primaryBackgroundColorHover
    green70: '#00645A', // WL: Not used yet
    green80: '#00352F', // WL: Not used yet
    green90: '#002420', // WL: Not used yet
    green100: '#83AAA6',
    gray10: '#EFF8F6', // WL: secondaryBackgroundColorHover
    gray20: '#E3EFEE', // WL: secondaryBackgroundColorActive
    gray30: '#C7D6D3', // WL: secondaryBackgroundColor
    gray40: '#ABBEBB', // WL: secondaryBackgroundColorDisabledBorder
    gray50: '#889E9B', // WL: secondaryTextColorPlaceholder
    gray60: '#748481', // WL: secondaryBackgroundColorBorder
    gray70: '#515F5C', // WL: secondaryTextColorHover
    gray80: '#363F3D', //
    gray90: '#1D2322', // WL: secondaryTextColor
    red10: '#FFE8E8', // WL: rentedPillBackgroundColor
    red20: '#FFD4D4',
    red30: '#FFA3A3',
    red40: '#FF777F',
    red50: '#F04050', // WL: rentedPillTextColor
    red60: '#DE2C3D',
    red70: '#C81C2C',
    red80: '#A00C18',
    red90: '#7C0008',
    orange10: '#FFF0E4', // WL: reservedPillBackgroundColor
    orange20: '#FFE1CC',
    orange30: '#FFBC8C',
    orange40: '#F89A56',
    orange50: '#F8822C', // WL: reservedPillTextColor
    orange60: '#E86E15',
    orange70: '#C75605',
    orange80: '#923D00',
    orange90: '#6B2D00',
    black: '#000000',
  },

  breakpoint: Object.entries(numericBreakpoints).reduce<Record<Size, string>>(
    (accumulator, [key, value]) => {
      return { ...accumulator, [key]: `${value}px` };
    },
    {} as Record<Size, string>,
  ),
} as const;

type Color = keyof typeof theme.color;
export interface PropstepTheme {
  color: Record<Color, string>;
  breakpoint: Record<Size, string>;
}
