/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AvailabilityStatus {
    AVAILABLE = 'Available',
    RESERVED = 'Reserved',
    RENTED = 'Rented',
}
