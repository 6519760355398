import charmap from '../json/characters/charmap.json';

type CharMapCharacter = keyof typeof charmap;

export function removeEmptyValues(obj: Record<string, any>): Record<string, any> {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (value && value !== '[]' && (!Array.isArray(value) || value.length) && value !== 'null') {
      return { ...accumulator, [key]: value };
    }
    return accumulator;
  }, {});
}

export function isEmpty(obj: object): boolean {
  return Object.entries(obj).length === 0;
}

export function shallowCompare(a: { [key: string]: any }, b: { [key: string]: any }) {
  return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((key) => b.hasOwnProperty(key) && a[key] === b[key]);
}

export function getIntegerRange(length: number, start: number = 1): number[] {
  return Array.from(Array(length - start + 1).keys()).map((num) => num + start);
}

export function stringifyAllValues(obj: object) {
  return Object.entries(obj).reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: sortArrayAsString(value) }), {});
}

export function sortArrayAsString(value: any): string {
  if (Array.isArray(value)) {
    return JSON.stringify(value.sort());
  }
  const valueAsString = value.toString();
  if (!valueAsString.startsWith('[') || !valueAsString.endsWith(']')) {
    return valueAsString;
  }
  const parsedArray = JSON.parse(valueAsString);
  return JSON.stringify(parsedArray.sort());
}

export function insertIntoArray(array: string[], position: number, entry: string): string[] {
  return [...array.slice(0, position), entry, ...array.slice(position)];
}

export function insertIntoString(original: string, position: number, entry: string, separator: string = ' '): string {
  return insertIntoArray(original.split(separator), position, entry).join(separator);
}

export function kebabCase(value: string): string {
  return value.toLowerCase().replace(/[^a-z0-9]+/g, (char) => charmap[char as CharMapCharacter] || '-');
}

export function getYearsDiff(date: Date): number {
  return new Date(Date.now() - date.getTime()).getFullYear() - 1970;
}

export const createPostalCodeRange = (firstPostalCode: number | undefined, lastPostalCode: number | undefined) => {
  if (!firstPostalCode || !lastPostalCode) {
    return;
  }

  return {
    start: firstPostalCode.toString(),
    end: lastPostalCode.toString(),
  };
};
