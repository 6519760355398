import { ThemeProvider } from 'styled-components';
import { useWhiteLabel } from './WhiteLabelProvider';
import { PropstepTheme, theme } from '../styles/theme';
import { WhiteLabelTheme } from '../types/api/whiteLabel';

interface CombinedThemeProviderProps {
  children: React.ReactNode;
}
const CombinedThemeProvider = ({ children }: CombinedThemeProviderProps) => {
  const { theme: whiteLabelTheme } = useWhiteLabel();
  const { color: propstepColor, ...restPropstepTheme } = theme;
  const { color: whiteLabelColor, ...restWhiteLabelTheme } = whiteLabelTheme;

  const combinedColor = { ...propstepColor, ...whiteLabelColor };
  const combinedTheme: PropstepTheme & WhiteLabelTheme = {
    color: combinedColor,
    ...restPropstepTheme,
    ...restWhiteLabelTheme,
  };

  return <ThemeProvider theme={combinedTheme}>{children}</ThemeProvider>;
};

export default CombinedThemeProvider;
