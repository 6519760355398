import Cookies from 'js-cookie';
import { GTM_ID } from '../utils/constants';
import { useAtom, useAtomValue } from 'jotai';
import { userState } from '../state/userState';
import { useState } from 'react';
import { WhiteLabelConfig } from '../types/api/whiteLabel';

interface DataLayerObject {
  event: string;
  userId?: string;

  [key: string]: any;
}

declare global {
  interface Window {
    dataLayer: DataLayerObject[];
  }
}

function pageWasNotReloaded() {
  if (!performance) {
    return false;
  }
  const entries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
  return entries?.length > 0 && entries[0].type == 'navigate';
}

function setCookieIfNotNull(key: string, value: string | null) {
  if (value != null) {
    document.cookie = `${key}=${encodeURIComponent(value)}; path=/; max-age=2592000`;
  }
}

export function isFirstPageLoad() {
  const isFirstLoad = !sessionStorage.getItem('hasNavigated');
  if (isFirstLoad) {
    sessionStorage.setItem('hasNavigated', 'true');
  }
  return isFirstLoad;
}

function useDataLayer() {
  const isBrowser = typeof window !== 'undefined';
  const { user } = useAtomValue(userState);
  const [lastTrackedPageView, setLastTrackedPageView] = useState('');

  function setReferrerCookies() {
    const referrerFirstTouchCookie = Cookies.get('referrerFirstTouch');
    if (!referrerFirstTouchCookie) {
      setCookieIfNotNull('referrerFirstTouch', document.referrer);
    }
    setCookieIfNotNull('referrerLastTouch', document.referrer);
  }

  function setUTMCookies() {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_campaign = urlParams.get('utm_campaign');
    const utmSourceFirstTouchCookie = Cookies.get('utmSourceFirstTouch');
    if (!utmSourceFirstTouchCookie) {
      setCookieIfNotNull('utmSourceFirstTouch', utm_source);
      setCookieIfNotNull('utmMediumFirstTouch', utm_medium);
      setCookieIfNotNull('utmCampaignFirstTouch', utm_campaign);
    }
    setCookieIfNotNull('utmSourceLastTouch', utm_source);
    setCookieIfNotNull('utmMediumLastTouch', utm_medium);
    setCookieIfNotNull('utmCampaignLastTouch', utm_campaign);
  }

  const setWhiteLabelCookies = (whiteLabelConfig: WhiteLabelConfig) => {
    const whiteLabelCompanyIds = Cookies.get('whiteLabelCompanyIds');
    if (!whiteLabelCompanyIds) {
      setCookieIfNotNull('whiteLabelCompanyIds', whiteLabelConfig.companyIds.join(','));
    }
    const whiteLabelHostname = Cookies.get('whiteLabelHostname');
    if (!whiteLabelHostname) {
      setCookieIfNotNull('whiteLabelHostname', whiteLabelConfig.hostname);
    }
  };

  function getDataLayer() {
    return isBrowser ? (window.dataLayer = window.dataLayer || []) : [];
  }

  function setAttributionCookies() {
    if (isFirstPageLoad() && pageWasNotReloaded()) {
      setReferrerCookies();
      setUTMCookies();
    }
  }

  function setCompanyIdAndPropertyGroupIdFirstTouch(companyId: string, propertyGroupId: string = '') {
    const propertyGroupIdFirstTouch = Cookies.get('propertyGroupIdFirstTouch');
    const companyIdFirstTouch = Cookies.get('companyIdFirstTouch');
    if (!propertyGroupIdFirstTouch && !companyIdFirstTouch) {
      setCookieIfNotNull('propertyGroupIdFirstTouch', propertyGroupId);
      setCookieIfNotNull('companyIdFirstTouch', companyId);
    }
  }

  function getAttributionData() {
    return {
      utm_source_first_touch: Cookies.get('utmSourceFirstTouch'),
      utm_medium_first_touch: Cookies.get('utmMediumFirstTouch'),
      utm_campaign_first_touch: Cookies.get('utmCampaignFirstTouch'),
      utm_source_last_touch: Cookies.get('utmSourceLastTouch'),
      utm_medium_last_touch: Cookies.get('utmMediumLastTouch'),
      utm_campaign_last_touch: Cookies.get('utmCampaignLastTouch'),
      referrer_first_touch: Cookies.get('referrerFirstTouch'),
      referrer_last_touch: Cookies.get('referrerLastTouch'),
      company_id_first_touch: Cookies.get('companyIdFirstTouch'),
      property_group_id_first_touch: Cookies.get('propertyGroupIdFirstTouch'),
      white_label_company_ids: Cookies.get('whiteLabelCompanyIds'),
      white_label_hostname: Cookies.get('whiteLabelHostname'),
    };
  }

  function getWhiteLabelData() {
    return {
      whiteLabelCompanyIds: Cookies.get('whiteLabelCompanyIds'),
      whiteLabelHostname: Cookies.get('whiteLabelHostname'),
    };
  }

  async function push(dataLayerObject: DataLayerObject) {
    if (GTM_ID && isBrowser) {
      const data = {
        referrer: document.referrer,
        url: window.location.href,
        title: document.title,
        userId: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        ...dataLayerObject,
        ...getAttributionData(),
        ...getWhiteLabelData(),
      };
      getDataLayer().push(data);
    }
  }

  const trackPageNavigation = () => {
    const path = window.location.pathname;
    if (path != lastTrackedPageView) {
      push({ event: `page_view`, path: path, search: window.location.search });
      setLastTrackedPageView(path);
    }
  };

  return {
    push: push,
    setAttributionCookies: setAttributionCookies,
    getAttributionData: getAttributionData,
    trackPageNavigation: trackPageNavigation,
    setWhiteLabelCookies: setWhiteLabelCookies,
    setCompanyIdAndPropertyGroupIdFirstTouch: setCompanyIdAndPropertyGroupIdFirstTouch,
  };
}

export default useDataLayer;
